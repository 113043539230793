import s from './index.module.css';

import cn from 'classnames';
import InputMask from 'react-input-mask';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { FormValues } from 'layouts/Modals/PurchaseFormModal';

import { ArrowRight } from 'assets/icons';

interface InputProps {
  title?: 'E-mail' | 'Имя' | 'Фамилия' | 'Телефон';
  name: 'name' | 'email' | 'surname' | 'phone';
  required?: boolean;
  placeholder?: string;
  errors: any;
  register: UseFormRegister<FormValues>;
  onClick?: () => void;
  withAction?: boolean;
  control?: Control<FormValues>;
}

const Input = ({
  title,
  name,
  required = false,
  register,
  errors,
  placeholder,
  onClick,
  withAction = false,
  control,
  ...rest
}: InputProps) => {
  return (
    <div className={cn(s.group, { [s.group_small]: withAction })}>
      {title && (
        <label className={s.label} htmlFor={name}>
          {title}
        </label>
      )}
      <div className={cn(s.container, { [s.error]: errors && errors[name] })}>
        {name === 'phone' && control ? (
          <Controller
            control={control}
            name={name}
            rules={{
              required: required,
            }}
            render={({ field }) => (
              <InputMask
                className={s.input}
                mask='+7 (\999) 999-99-99'
                {...field}
              />
            )}
          />
        ) : (
          <input
            id={name}
            type='text'
            autoComplete='off'
            className={s.input}
            placeholder={placeholder}
            {...register(name, {
              required: required,
            })}
            {...rest}
          />
        )}
        {withAction && (
          <div className={s.action} onClick={onClick}>
            <ArrowRight />
          </div>
        )}
      </div>
      {errors && errors[name] && (
        <div className={s.label_error}>{errors[name].message}</div>
      )}
    </div>
  );
};

export { Input };
