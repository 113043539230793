import s from "./Ticket.module.css";
import cn from "classnames";

interface TicketProps {
  time: { from: string; to?: string };
  price: number;
  title: string;
  image: string;
  onClick(): void;
  active: boolean;
  className?: string;
  smallTicket?: boolean;
}

const Ticket = ({
  time,
  price,
  title,
  image,
  onClick,
  active,
  className = "",
  smallTicket,
}: TicketProps) => {
  if (smallTicket) {
    return (
      <div
        className={cn(s.root, className, {
          [s.active]: active,
          [s.small]: smallTicket,
        })}
        onClick={onClick}
      >
        <div className={s.contentWithImage}>
          <img className={s.image} src={image} alt="Ticket" />
          <span className={s.time}>
            С {time.from}
            {time.to ? ` до ${time.to}` : ""}
          </span>
          <span className={s.price}>От {price} ₽</span>
        </div>
        <h2 className={s.title}>{title}</h2>
      </div>
    );
  }

  return (
    <div
      className={cn(s.root, className, { [s.active]: active })}
      onClick={onClick}
    >
      <img className={s.image} src={image} alt="Ticket" />
      <div className={s.content}>
        <span className={s.time}>
          С {time.from}
          {time.to ? ` до ${time.to}` : ""}
        </span>
        <h2 className={s.title}>{title}</h2>
        <span className={s.price}>От {price} ₽</span>
      </div>
    </div>
  );
};

export { Ticket };
