import { Marker, MarkerProps } from "react-leaflet";
import * as ReactDOMServer from "react-dom/server";
import s from "./index.module.css";

import L from "leaflet";
import { CustomPopup } from "../Popup";
import { memo, useEffect, useRef, useState } from "react";

import cn from "classnames";
import { OrderedDot } from "assets/icons";
import { useWindowWidth } from "hooks/useWindowWidth";
import { useSelector } from "react-redux";
import { selectOrder } from "store/common/selectors";
import { IOrder, IPoolZone } from "types";

type CustomMarkerProps = {
  isActive: boolean;
  isOrdered: boolean;
  color: string;
};

export const CustomMarkerIcon = ({
  isActive,
  isOrdered,
  color,
}: CustomMarkerProps) => {
  return !isOrdered ? (
    <div
      className={cn(s.marker, {
        [s.marker_active]: isActive,
      })}
      style={{ backgroundColor: color }}
    ></div>
  ) : (
    <OrderedDot />
  );
};

const CustomMarker = (isActive: boolean, isOrdered: boolean, color: string) => {
  return new L.DivIcon({
    className: `${s.marker_icon}`,
    html: ReactDOMServer.renderToString(
      <CustomMarkerIcon
        color={color}
        isActive={isActive}
        isOrdered={isOrdered}
      />
    ),
    iconSize: [0, 0],
    iconAnchor: [10, 10],
  });
};

interface Props extends MarkerProps {
  iconOptions?: L.DivIconOptions;
  place: IPoolZone;
  addToOrdered: (place: IPoolZone) => void;
  checkOrder: (id: number) => boolean;
  onMarkerClick: (place: any) => void;
  venueId: number;
  seatTypeId: number;
}

const JSXMarker = memo(
  ({
    children,
    iconOptions,
    place,
    venueId,
    seatTypeId,
    onMarkerClick,
    ...rest
  }: Props) => {
    const [isActive, setIsActive] = useState(false);
    const markerRef = useRef<any>(null);
    const windowWidth = useWindowWidth();
    const order = useSelector(selectOrder);
    const [isOrdered, setIsOrdered] = useState(false);
    const [orderId, setOrderId] = useState<IOrder | null>(null);

    useEffect(() => {
      const inOrder = order?.find((item) => item.venueId === venueId);
      if (inOrder) {
        setIsOrdered(true);
        setOrderId(inOrder);
      } else {
        setIsOrdered(false);
        setOrderId(null);
      }
    }, [order]);

    return (
      <Marker
        {...rest}
        icon={CustomMarker(isActive, isOrdered, place.color)}
        ref={markerRef}
        eventHandlers={{
          click: () => onMarkerClick(place),
        }}
      >
        {windowWidth && windowWidth > 768 && (
          <CustomPopup
            isOrdered={isOrdered}
            {...place.popupData}
            orderId={orderId?.id ?? 1}
            price={place.price}
            venueId={venueId}
            seatTypeId={seatTypeId}
            setIsActive={(active: boolean) => setIsActive(active)}
          />
        )}
      </Marker>
    );
  }
);

export { JSXMarker };
