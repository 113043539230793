import { FallbackLoader } from 'components/Loaders/FallbackLoader';
import { useSelector } from 'react-redux';

import React from 'react';
import { isGlobalLoading } from 'store/common/selectors';

// ----------------------------------------------------------------------

export const GlobalPreloader: React.FC<
  React.PropsWithChildren<{ showLoading: boolean }>
> = ({ children, showLoading }) => {
  const isLoading = useSelector(isGlobalLoading);

  return (
    <>
      {showLoading ? (
        <>
          {isLoading && <FallbackLoader />}
          {children}
        </>
      ) : (
        <> {children}</>
      )}
    </>
  );
};
