import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IGlobalEvents, IOrder, IPrices, IVenue, RequestStatus } from 'types';
import {
  addOrder,
  getEventVenue,
  getGlobalEvents,
  getPrices,
  getPricesMap,
  getServiceId,
  getSheduleForMonth,
  getSheduleIdOneDate,
  initializedOrder,
  removeOrder,
} from './actions';

export interface ICommonReducerState {
  /**
   * @description нужен для передачи в постзапрос поля дат
   */
  serviceId: number | null;
  /**
   * @description на текущий выбранный день в дейтпикере
   */
  scheduleOneDay: {
    scheduleId: number | null;
    venueId: number | null;
  };
  /**
   * @description айдишник для текущего заказа
   */
  publicId: null | string;

  scheduleForMonth: Date[];

  // карточки которые будем отрисовывать в асайде и в модалке
  globalEvents: IGlobalEvents[];
  currentGlobalEvent: IGlobalEvents | null;

  eventVenue: IVenue[];
  currentPricesFree: IPrices[];
  currentPricesMap: IPrices[];

  order: IOrder[];

  isMap: boolean;
  requestOrderStatus: RequestStatus;
  // общее - для обработки состояний запросов
  requestStatus: RequestStatus;
  requestError: string | null;
}

const getInitialState = (): ICommonReducerState => ({
  serviceId: null,
  scheduleOneDay: {
    scheduleId: null,
    venueId: null,
  },
  publicId: null,

  scheduleForMonth: [],

  globalEvents: [],
  currentGlobalEvent: null,

  eventVenue: [],
  currentPricesFree: [],
  currentPricesMap: [],
  order: [],
  requestOrderStatus: RequestStatus.NotInitialized,
  isMap: true,
  requestStatus: RequestStatus.NotInitialized,
  requestError: null,
});

const commonSlice = createSlice({
  name: 'common',
  initialState: getInitialState(),
  reducers: {
    setCurrentGlobalEvent: (state, action: PayloadAction<IGlobalEvents>) => {
      state.currentGlobalEvent = action.payload;
    },
    setIsMap: (state, action: PayloadAction<boolean>) => {
      state.isMap = action.payload;
    },
    clearSession: () => {
      return getInitialState();
    },
    clearOrder: (state) => {
      state.order = getInitialState().order;
    },
  },
  extraReducers: (builder) => {
    builder
      // TODO: добавить обработку ошибок
      .addCase(getServiceId.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(getServiceId.rejected, (state, action) => {
        // state.requestError = action.error;
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(getServiceId.fulfilled, (state, action) => {
        state.requestStatus = RequestStatus.Fulfilled;
        state.serviceId = action.payload;
      })
      // ___ //
      .addCase(getSheduleIdOneDate.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(getSheduleIdOneDate.rejected, (state, action) => {
        // state.requestError = action.error;
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(getSheduleIdOneDate.fulfilled, (state, action) => {
        state.requestStatus = RequestStatus.Fulfilled;
        state.scheduleOneDay.scheduleId = action.payload.serviceScheduleId;
        state.scheduleOneDay.venueId = action.payload.venueId;
      })
      // ___ //
      .addCase(initializedOrder.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(initializedOrder.rejected, (state, action) => {
        // state.requestError = action.error;
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(initializedOrder.fulfilled, (state, action) => {
        state.requestStatus = RequestStatus.Fulfilled;
        state.publicId = action.payload;
      })
      // ___ //
      .addCase(getGlobalEvents.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(getGlobalEvents.rejected, (state, action) => {
        // state.requestError = action.error;
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(getGlobalEvents.fulfilled, (state, action) => {
        state.requestStatus = RequestStatus.Fulfilled;
        state.globalEvents = action.payload;
      })
      // __ //
      .addCase(getSheduleForMonth.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(getSheduleForMonth.rejected, (state, action) => {
        // state.requestError = action.error;
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(getSheduleForMonth.fulfilled, (state, action) => {
        state.requestStatus = RequestStatus.Fulfilled;
        state.scheduleForMonth = action.payload;
      })
      //
      .addCase(getEventVenue.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(getEventVenue.rejected, (state, action) => {
        // state.requestError = action.error;
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(getEventVenue.fulfilled, (state, action) => {
        state.requestStatus = RequestStatus.Fulfilled;
        state.eventVenue = action.payload;
      })
      // ___ //
      .addCase(getPrices.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(getPrices.rejected, (state, action) => {
        // state.requestError = action.error;
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(getPrices.fulfilled, (state, action) => {
        if (!state.isMap) {
          state.currentPricesFree = action.payload;
        }
        state.requestStatus = RequestStatus.Fulfilled;
      })
      .addCase(getPricesMap.pending, (state) => {
        state.requestStatus = RequestStatus.Pending;
      })
      .addCase(getPricesMap.rejected, (state, action) => {
        // state.requestError = action.error;
        state.requestStatus = RequestStatus.Rejected;
      })
      .addCase(getPricesMap.fulfilled, (state, action) => {
        if (state.isMap) {
          state.currentPricesMap = action.payload;
        }
        state.requestStatus = RequestStatus.Fulfilled;
      })
      // ___ //
      .addCase(addOrder.pending, (state) => {
        state.requestOrderStatus = RequestStatus.Pending;
      })
      .addCase(addOrder.rejected, (state, action) => {
        // state.requestError = action.error;
        state.requestOrderStatus = RequestStatus.Rejected;
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.requestOrderStatus = RequestStatus.Fulfilled;
        state.order = action.payload;
      })
      // ___ //
      .addCase(removeOrder.pending, (state) => {
        state.requestOrderStatus = RequestStatus.Pending;
      })
      .addCase(removeOrder.rejected, (state, action) => {
        // state.requestError = action.error;
        state.requestOrderStatus = RequestStatus.Rejected;
      })
      .addCase(removeOrder.fulfilled, (state, action) => {
        state.requestOrderStatus = RequestStatus.Fulfilled;
        state.order = action.payload;
      });
  },
});

export const { reducer } = commonSlice;

export const actions = {
  ...commonSlice.actions,
};
