import { DatePicker } from "components/DatePicker";
import { Head } from "components/Head";
import { FieldValues, useForm } from "react-hook-form";

import s from "./index.module.css";
import { Ticket } from "components/Ticket";
import { Scrollbar } from "react-scrollbars-custom";

import { ISOToTimeFormat } from "utils/data";

import { Loader } from "components/Loaders/Loader";

import {
  isGlobalLoading,
  selectCurrentGlobalEvent,
  selectGlovbalEvents,
} from "store/common/selectors";

import { useSelector } from "react-redux";
import { useDispatch } from "store/store";

import { actions as commonActions } from "store/common/reducer";
import { getEventVenue, getPrices } from "store/common/actions";
import { useWindowWidth } from "hooks/useWindowWidth";

interface AsideProps {
  onClickDate: (date: Date) => void;
  chosenDate: Date;
  onTicketClick: (time: string) => void;
}

const Aside = ({ onClickDate, chosenDate, onTicketClick }: AsideProps) => {
  const { control } = useForm<FieldValues>({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();

  const globalEvents = useSelector(selectGlovbalEvents);
  const currentEvent = useSelector(selectCurrentGlobalEvent);
  const isLoading = useSelector(isGlobalLoading);

  const handleChangeDate = (date: Date) => {
    onClickDate(date);
  };

  return (
    <aside className={s.aside}>
      <Scrollbar className={s.scrollbar}>
        <div className={s.wrapper}>
          <div className={s.header}>
            <Head
              className={s.head}
              withLogo
              link="https://dreamisland.ru/dream-beach/"
            />
          </div>
          <DatePicker
            control={control}
            className={s.datepicker}
            chosenDate={chosenDate}
            onChangeDate={handleChangeDate}
            name="date"
          />
          <div className={s.items}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {!!globalEvents.length &&
                  globalEvents.map((event) => {
                    const {
                      minPrice,
                      seatTypeGroup: { id, icon, name },
                      schedule: { from, to, venueId, id: sheduleId },
                    } = event;
                    const time = {
                      from: ISOToTimeFormat(from.toString()).dateTemplate,
                      to: ISOToTimeFormat(to.toString()).dateTemplate,
                    };

                    return (
                      <Ticket
                        key={id}
                        title={name}
                        time={time}
                        price={minPrice}
                        image={icon ?? "/images/tickets/ticket-2.png"}
                        onClick={() => {
                          dispatch(commonActions.setCurrentGlobalEvent(event));

                          onTicketClick(
                            `С ${time.from.slice(0, 2).replace(/^0/, "")} ${
                              time.to ? ` до ${time.to.slice(0, 2)}` : ""
                            }`
                          );
                          dispatch(getEventVenue({ rootId: venueId, id: id }))
                            .unwrap()
                            .then((payload) => {
                              if (payload[0]) {
                                dispatch(
                                  getPrices({
                                    serviceScheduleId: sheduleId,
                                    venueId: payload[0].id,
                                  })
                                );
                              }
                            });
                        }}
                        smallTicket={!!windowWidth && windowWidth <= 900}
                        active={currentEvent?.seatTypeGroup.id === id}
                      />
                    );
                  })}
              </>
            )}
          </div>
        </div>
      </Scrollbar>
    </aside>
  );
};

export { Aside };
