import { useState } from "react";
import { Button } from "components/Button";
import { PortalModal } from "../index";
import { Ticket } from "components/Ticket";
import cn from "classnames";
import s from "./TicketModal.module.css";
import { useWindowWidth } from "hooks/useWindowWidth";

import { ISOToTimeFormat } from "utils/data";

import {
  selectCurrentGlobalEvent,
  selectGlovbalEvents,
} from "store/common/selectors";

import { useSelector } from "react-redux";
import { useDispatch } from "store/store";
import { IGlobalEvents } from "types";

import { actions as commonActions } from "store/common/reducer";
import { getEventVenue, getPrices } from "store/common/actions";

interface TicketModalProps {
  onClick: () => void;
  onCloseClick: () => void;
  onTicketClick: (time: string) => void;
}

const TicketModal = ({
  onClick,
  onCloseClick,
  onTicketClick,
}: TicketModalProps) => {
  const dispatch = useDispatch();

  const [activeTicket, setActiveTicket] = useState<IGlobalEvents | null>(null);

  const globalEvents = useSelector(selectGlovbalEvents);

  const windowWidth = useWindowWidth();

  const onTicketClickHandler = (event: IGlobalEvents, time: string) => {
    const {
      seatTypeGroup: { id },
      schedule: { venueId, id: sheduleId },
    } = event;
    onTicketClick(time);
    if (id !== activeTicket?.seatTypeGroup.id) {
      setActiveTicket(event);
      dispatch(commonActions.setCurrentGlobalEvent(event));
      dispatch(getEventVenue({ rootId: venueId, id: id }))
        .unwrap()
        .then((payload) => {
          if (payload[0]) {
            dispatch(
              getPrices({
                serviceScheduleId: sheduleId,
                venueId: payload[0].id,
              })
            );
          }
        });
    } else setActiveTicket(null);
    if (windowWidth && windowWidth <= 768) {
      onClick();
    }
  };

  return (
    <PortalModal
      onClickForClose={() => null}
      onClick={onCloseClick}
      withHeader
      headerArrowType="arrow"
      headerClassName={s.head}
      headerTextClassName={cn(s.headerText, {
        [s.headerTextActive]: activeTicket !== null,
      })}
      overlayType="blur"
    >
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.root}>
            <h2 className={s.title}>Выберите билет</h2>

            <div className={s.items}>
              {!!globalEvents.length &&
                globalEvents.map((event) => {
                  const {
                    minPrice,
                    seatTypeGroup: { id, icon, name },
                    schedule: { from, to },
                  } = event;
                  const time = {
                    from: ISOToTimeFormat(from.toString()).dateTemplate,
                    to: ISOToTimeFormat(to.toString()).dateTemplate,
                  };

                  return (
                    <Ticket
                      key={id}
                      title={name}
                      time={time}
                      price={minPrice}
                      image={icon ?? "/images/tickets/ticket-2.png"}
                      smallTicket={!!activeTicket}
                      onClick={() =>
                        onTicketClickHandler(
                          event,
                          `С ${time.from.slice(0, 2).replace(/^0/, "")} ${
                            time.to ? ` до ${time.to.slice(0, 2)}` : ""
                          }`
                        )
                      }
                      active={activeTicket?.seatTypeGroup.id === id}
                      className={s.item}
                    />
                  );
                })}
            </div>
          </div>
          {windowWidth && windowWidth > 768 && (
            <>
              {!!globalEvents.length &&
                globalEvents
                  .filter(
                    (el) =>
                      activeTicket?.seatTypeGroup.id === el.seatTypeGroup.id
                  )
                  .map(({ seatTypeGroup: { id, image, description } }) => (
                    <div
                      className={cn(s.additional, {
                        [s.active]: !!activeTicket,
                      })}
                      key={id}
                    >
                      <div className={s.container}>
                        <img
                          className={s.additionalImage}
                          src={image ?? "/images/tickets/ticket-inner.png"}
                          alt=""
                        />
                      </div>
                      <div
                        className={s.additionalTextWrapper}
                        dangerouslySetInnerHTML={{
                          __html: description ? description : "нет инфы с апи",
                        }}
                      />
                      {activeTicket && (
                        <Button onClick={onClick} className={s.button}>
                          Продолжить
                        </Button>
                      )}
                    </div>
                  ))}
            </>
          )}
        </div>
      </div>
    </PortalModal>
  );
};

export { TicketModal };
