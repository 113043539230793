import { Popup, useMapEvents } from 'react-leaflet';
import s from './index.module.css';

import './popup.css';

import { CloseIcon } from 'assets/icons';
import { Button } from 'components/Button';
import { useRef } from 'react';
import { useDispatch } from 'store/store';
import { addOrder, removeOrder } from 'store/common/actions';
import { removeFromOrder } from 'services';

import { Scrollbar } from 'react-scrollbars-custom';

interface CustomPopupProps {
  // тут пиздец пропов и всякой хуйни, надо будет отрефачить парашу
  image: string;
  title: string;
  zone: string;
  description: string;
  price: number;
  setIsActive: (active: boolean) => void;
  venueId: number;
  seatTypeId: number;
  isOrdered: boolean;
  orderId: number;
}

const CustomPopup = ({
  image,
  title,
  zone,
  description,
  price,
  setIsActive,
  venueId,
  seatTypeId,
  isOrdered,
  orderId,
}: CustomPopupProps) => {
  const popupElRef = useRef(null);
  const dispatch = useDispatch();

  const closePopup = () => {
    if (!popupElRef.current) return;
    //@ts-ignore
    popupElRef.current._closeButton.click();
  };

  // лифлет двигает карту чтобы попап вместился в текущий размер экрана
  // из-за этого появляются полосы
  useMapEvents({
    popupopen: (e) => setTimeout(() => (e.popup.options.autoPan = false)),
    popupclose: (e) => (e.popup.options.autoPan = true),
  });

  return (
    <Popup
      className={s.popup}
      maxWidth={325}
      ref={popupElRef}
      eventHandlers={{
        add: () => setIsActive(true),
        remove: () => setIsActive(false),
      }}
      // так как верстка попапа адаптировна под концепт
      // лифлету необходимо понимать где расположен попап, чтобы открывать его там где нужно
      offset={[-220, 400]}
    >
      <div className={s.root}>
        <div className={s.main}>
          <div className={s.top}>
            <img src={image} alt='Лежак' className={s.image} />
            <button className={s.close} onClick={closePopup}>
              <CloseIcon />
            </button>
          </div>
          <h3 className={s.title}>{title}</h3>
          <span className={s.zone}>{zone}</span>
          <Scrollbar className={`${s.scrollbar} edit`}>
            <div
              className={s.options}
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </Scrollbar>
          <span className={s.price}>{price} ₽</span>
        </div>
        <Button
          className={s.button}
          onClick={() => {
            if (!isOrdered) {
              dispatch(addOrder({ venueId, seatTypeId }));
            } else {
              dispatch(removeOrder({ orderDetailId: orderId }));
            }

            closePopup();
          }}
        >
          {!isOrdered ? 'Добавить в заказ' : 'Убрать из заказа'}
        </Button>
      </div>
    </Popup>
  );
};

export { CustomPopup };
