import { INTEGRATION_ID, LIME_URL } from 'global/consts';

let _g_resolvedUrl = "";
let _g_resolvedIntegrationId = "";

function resolveUrl(): string {
  if (_g_resolvedUrl) return _g_resolvedUrl;

  const sp = new URLSearchParams(window.location.search);

  let url = <string>(sp.get('x-lime-api') ?? LIME_URL);

  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }


  if (!url.endsWith('/api')) {
    url = `${url}/api`;
  }

  _g_resolvedUrl = url;
  return _g_resolvedUrl;
}

function resolveIntegrationId(): string {
  if (_g_resolvedIntegrationId) return _g_resolvedIntegrationId;

  const sp = new URLSearchParams(window.location.search);

  _g_resolvedIntegrationId = <string>(sp.get('x-lime-integrationId') ?? INTEGRATION_ID);

  return _g_resolvedIntegrationId;
}

// Получение интеграции - вытаскиваем seekFileds
export const getIntegration = async () => {
  const request = await fetch(
    `${resolveUrl()}/Shop/GetIntegration?integrationId=${resolveIntegrationId()}`
  );
  const response = await request.json();

  return response.seekFields;
};

// Из этого запроса забираем serviceId (только там 6 массивов, а работает айди из [0])
export const getShopEvents = async () => {
  const request = await fetch(`${resolveUrl()}/Shop/Events/${resolveIntegrationId()}`);
  const response = await request.json();

  return response[0].id;
};

// Тут карточки из второй модалки, то есть карточки ивентов доступных
// Параметры: serviceId - айди из прошлого запроса; from/to - один день на который хотим посмотреть ивенты
export const getEventSchedules = async (options: {
  serviceId: number | null;
  from: string;
  to: string;
}) => {
  const request = await fetch(
    `${resolveUrl()}/Shop/EventSchedules/${resolveIntegrationId()}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(options),
    }
  );
  const response = await request.json();

  return response.schedules;
};

// Тут лежат пины для карты и актуальные цены для каждого ивента
export const getEventVenueSeatTypeGroups = async (options: {
  serviceId: number | null;
  from: string;
  to: string;
}) => {
  const request = await fetch(
    `${resolveUrl()}/Shop/EventVenueSeatTypeGroups/${resolveIntegrationId()}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(options),
    }
  );
  const response = await request.json();

  return response.seatTypeGroups;
};

// Тут лежат пины для карты и актуальные цены для каждого ивента
export const getShopEventPrices = async (
  serviceScheduleId: number,
  venueId: number
) => {
  const request = await fetch(
    `${resolveUrl()}/Shop/EventPrices/${resolveIntegrationId()}/${serviceScheduleId}/${venueId}`
  );
  const response = await request.json();

  return response.prices;
};

export const getShopEventVenue = async (rootId: number, id: number) => {
  const options = {
    rootId: rootId,
    id: null,
    parentId: null,
    seatTypeGroupIds: [id],
    seatTypeIds: null,
    kinds: [1, 2, 3],
  };
  const request = await fetch(`${resolveUrl()}/Shop/EventVenue/${resolveIntegrationId()}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(options),
  });
  const response = await request.json();

  return response.venues;
};

export const getShopOrder = async () => {
  const request = await fetch(`${resolveUrl()}/Shop/Order/${resolveIntegrationId()}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      mode: 1,
    }),
  });
  const response = await request.json();

  return response.order.publicId;
};

export const addToOrder = async (options: {
  publicOrderId: string;
  venueId: number;
  serviceScheduleId: number;
  seatTypeId: number | null;
}) => {
  const request = await fetch(`${resolveUrl()}/Shop/AddToOrder/${resolveIntegrationId()}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(options),
  });
  const response = await request.json();

  return response.details;
};

export const removeFromOrder = async (options: {
  publicOrderId: string;
  orderDetailId: number;
}) => {
  const request = await fetch(
    `${resolveUrl()}/Shop/RemoveFromOrder/${resolveIntegrationId()}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(options),
    }
  );
  const response = await request.json();

  return response.details;
};

export const confirmOrder = async (options: {
  publicOrderId: string;
  returnUrl: string;
  email: string;
}) => {
  const request = await fetch(
    `${resolveUrl()}/Shop/ConfirmOrder/${resolveIntegrationId()}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(options),
    }
  );
  const response = await request.json();

  return response;
};

export const processOrder = async (publicOrderId: string) => {
  const request = await fetch(`${resolveUrl()}/Shop/ProcessOrder/${publicOrderId}`);
  const response = await request.json();

  return response;
};

export const getTicket = async (publicOrderId: string) => {
  const request = await fetch(`${resolveUrl()}/Shop/Ticket/${publicOrderId}`, {});
  const blob = await request.blob();

  const fileURL = window.URL.createObjectURL(blob);
  let alink = document.createElement('a');
  alink.href = fileURL;

  alink.download = `DreamBeachClub_${
    new Date(Date.now()).toLocaleString().split(',')[0]
  }.pdf`;
  alink.click();
  window.open(fileURL);
};
