import { Button } from "components/Button";
import { PortalModal } from "../index";
import cn from "classnames";

import s from "./index.module.css";
import { BeachIcon, CloseIcon } from "assets/icons";
import { OrderedItem } from "components/OrderedItem";
import { useEffect } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import { IPoolZone } from "types";

interface EditOrderModalProps {
  onCloseModalClickHandler: () => void;
  isModalOpen: boolean;
  orderedItems: IPoolZone[];
  totalSum: number;
}

const EditOrderModal = ({
  isModalOpen,
  onCloseModalClickHandler,
  orderedItems,
  totalSum,
}: EditOrderModalProps) => {
  useEffect(() => {
    if (!orderedItems.length) {
      onCloseModalClickHandler();
    }
  }, [onCloseModalClickHandler, orderedItems]);

  return isModalOpen ? (
    <PortalModal
      withHeader={false}
      onClickForClose={onCloseModalClickHandler}
      overlayType="blur"
    >
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.root}>
            <div className={s.top}>
              <h3 className={s.title}>Редактирование заказа</h3>
              <button className={s.close} onClick={onCloseModalClickHandler}>
                <CloseIcon />
              </button>
            </div>
            <div className={s.options}>
              <h6 className={s.options_title}>Выбрано</h6>
              <div className={s.price}>
                <p className={s.price__text}>
                  {`${orderedItems.length} объекта на ${orderedItems.length} человека`}{" "}
                </p>
                <span className={s.price__sum}>{totalSum} ₽</span>
              </div>
            </div>
            <Scrollbar className={cn(s.scrollbar, "edit")}>
              <div className={s.items}>
                {orderedItems.map((item) => (
                  <OrderedItem
                    icon={<BeachIcon />}
                    key={item.id}
                    item={item}
                    lenght={orderedItems.length}
                  />
                ))}
              </div>
            </Scrollbar>

            <Button
              className={s.button}
              onClick={() => {
                onCloseModalClickHandler();
              }}
            >
              Сохранить изменения
            </Button>
          </div>
        </div>
      </div>
    </PortalModal>
  ) : null;
};

export { EditOrderModal };
