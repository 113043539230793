import { Button } from "components/Button";
import { PortalModal } from "../index";

import s from "./index.module.css";
import { useForm } from "react-hook-form";
import { Input } from "components/Input";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  REGEXP_EMAIL,
  REGEXP_NAME,
  REGEXP_PHONE,
  REGEXP_SURMANE,
} from "utils/RegExp";
import { useSelector } from "react-redux";
import { selectTotalOrderSum } from "store/common/selectors";
import { confirmOrder } from "store/common/actions";
import { useDispatch } from "store/store";
import { toIsoString } from "utils/data";

export interface FormValues {
  name: string;
  email: string;
  surname: string;
  phone: string;
}

interface PurchaseFormModalProps {
  onClickForClose: () => void;
  chosenDate: Date;
}

const messageRequired = "Поле обязательно для заполнения";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required(messageRequired)
    .matches(REGEXP_EMAIL, "Некорректный email"),
  name: yup
    .string()
    .required(messageRequired)
    .matches(REGEXP_NAME, "Некорректное имя"),
  surname: yup
    .string()
    .required(messageRequired)
    .matches(REGEXP_SURMANE, "Некорректное фамилия"),
  phone: yup
    .string()
    .required(messageRequired)
    .matches(REGEXP_PHONE, "Некорректный номер телефона"),
});

const PurchaseFormModal = ({
  onClickForClose,
  chosenDate,
}: PurchaseFormModalProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  // 2023-06-07T00:00:00+03:00
  const date = toIsoString(chosenDate);
  const orderDateIndex = date.indexOf("T");
  const orderDate = date.slice(0, orderDateIndex);

  const dispatch = useDispatch();

  const sumToPay = useSelector(selectTotalOrderSum);

  const totalPricesBlock = [
    {
      id: 1,
      text: `Итого к оплате`,
      price: sumToPay,
    },
  ];

  const onSubmit = async (data: FormValues) => {
    const { email } = data;
    await dispatch(confirmOrder({ email, date: orderDate }))
      .unwrap()
      .then(({ paymentUrl }) => {
        window.location.assign(paymentUrl);
      });
  };

  return (
    <PortalModal
      headerLogoClassName={s.logo}
      headerTextClassName={s.headerText}
      headerClassName={s.header}
      onClick={onClickForClose}
      headerArrowType="arrow"
      headerWithLogo
      onClickForClose={onClickForClose}
      overlayType="grey"
    >
      <div className={s.wrapper}>
        <div className={s.root}>
          <h2 className={s.title}>Введите данные для завершения покупки</h2>
          <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
            <Input
              title="E-mail"
              name="email"
              required
              register={register}
              errors={errors}
            />
            <Input
              title="Имя"
              name="name"
              required
              register={register}
              errors={errors}
            />
            <Input
              title="Фамилия"
              name="surname"
              required
              register={register}
              errors={errors}
            />
            <Input
              title="Телефон"
              name="phone"
              required
              register={register}
              errors={errors}
              control={control}
            />
            <p className={s.text}>
              На указанные вами данные будут отправлены электронные копии
              приобретенных билетов и чека
            </p>
            <div className={s.prices}>
              {totalPricesBlock.map((p) => (
                <div className={s.price} key={p.id}>
                  <p className={s.price__text}>{p.text}</p>
                  <span className={s.price__sum}>{p.price} ₽</span>
                </div>
              ))}
            </div>
            <Button
              className={s.button}
              type="submit"
              disabled={!isValid}
              loading={isSubmitting}
            >
              Перейти к оплате
            </Button>
          </form>
        </div>
      </div>
    </PortalModal>
  );
};

export { PurchaseFormModal };
