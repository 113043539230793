import { useEffect, useState } from "react";
import { Button } from "components/Button";
import { PortalModal } from "../index";

import s from "./index.module.css";
import { LogoIcon } from "assets/icons";

import cn from "classnames";
import { Steps } from "types";
import { getParameterByName } from "utils/url";
import { Loader } from "components/Loaders/Loader";
import { useDispatch } from "store/store";
import { confirmOrder, processOrder } from "store/common/actions";
import { ISOToTimeFormat } from "utils/data";
import { getTicket } from "services";

interface PaymentStateModalProps {
  setStep: (step: Steps) => void;
}

const SuccessBlock = ({
  date,
}: {
  setStep: (step: Steps) => void;
  date: string;
}) => {
  const orderId = getParameterByName("orderId");

  return (
    <>
      <img
        src="/images/success_pay.png"
        alt="Фото DREAM BREACH CLUB"
        className={s.image}
      />
      <h2 className={s.title}>Спасибо за покупку!</h2>
      <p className={s.text}>
        Будем ждать вас
        <br />в Dream Beach Club {date}
      </p>
      <Button
        onClick={() => (window.location.href = window.location.origin)}
        className={s.button}
      >
        Купить другой билет
      </Button>
      <Button
        onClick={async () => await getTicket(orderId!)}
        className={s.button}
        variant="outlined"
      >
        Открыть билет
      </Button>
    </>
  );
};

const ErrorBlock = ({
  setStep,
  email,
  date,
}: {
  setStep: (step: Steps) => void;
  email: string;
  date: string;
}) => {
  const dispatch = useDispatch();
  const orderId = getParameterByName("orderId");

  return (
    <>
      <img src="/images/error_pay.svg" alt="Ошибка" className={s.image} />
      <h2 className={s.title}>Оплата не прошла</h2>
      <p className={cn(s.text, s.text_with_error)}>
        Вероятно, недостаточно средств
        <br /> на карте или данные карты были
        <br /> введены неверно. Также возможна
        <br /> проблема на стороне банка
      </p>
      <Button
        onClick={() => {
          dispatch(confirmOrder({ orderId: orderId!, email, date }))
            .unwrap()
            .then(({ paymentUrl }) => {
              window.location.assign(paymentUrl);
            });
        }}
        className={s.button}
      >
        Попробовать еще раз
      </Button>
      <Button
        onClick={() => setStep(Steps.DateChoice)}
        className={s.button}
        variant="outlined"
      >
        Купить другой билет
      </Button>
    </>
  );
};

type IPaymentStateType = "success" | "error";

const PaymentStateModal = ({ setStep }: PaymentStateModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [paymentState, setPaymentState] = useState<IPaymentStateType | null>(
    null
  );
  const [date, setDate] = useState("");
  const [email, setEmail] = useState<string>("");

  const dispatch = useDispatch();

  useEffect(() => {
    // null || query
    const orderId = getParameterByName("orderId");
    let date = getParameterByName("date");

    if (orderId && date) {
      setDate(ISOToTimeFormat(date, "long").monthTemplate.month);

      dispatch(processOrder({ publicOrderId: orderId }))
        .unwrap()
        .then(({ status, email }) => {
          if (status === 3) {
            setPaymentState("success");
          } else {
            setEmail(email);
            setPaymentState("error");
          }
        })
        .catch(() => {
          setPaymentState("error");
        });
    }
  }, []);

  const onCloseModalClickHandler = () => setIsModalOpen(false);

  if (!paymentState) {
    return (
      <PortalModal
        withHeader={false}
        onClickForClose={() => null}
        overlayType="light"
      >
        <Loader className={s.loader} />
      </PortalModal>
    );
  }

  return isModalOpen ? (
    <PortalModal
      withHeader={false}
      onClickForClose={onCloseModalClickHandler}
      overlayType="light"
    >
      <div className={s.wrapper}>
        <div className={s.root}>
          <div className={s.logo}>
            <LogoIcon width={75} height={63} />
          </div>
          {paymentState === "success" && (
            <SuccessBlock date={date} setStep={setStep} />
          )}
          {paymentState === "error" && (
            <ErrorBlock email={email} date={date} setStep={setStep} />
          )}
          <a href="https://dreamisland.ru/" target="_parent">
            <Button className={cn(s.button, s.button_transparent)}>
              Вернуться на главную
            </Button>
          </a>
        </div>
      </div>
    </PortalModal>
  ) : null;
};

export { PaymentStateModal };
