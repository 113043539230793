import { Dot } from "components/Dot";
import s from "./index.module.css";
import cn from "classnames";

import { ITag } from "types";

import { AnimationBlock } from "components/AnimationBlock";

interface TagsProps {
  tags: ITag[];
  isOpen: boolean;
  tagsClassName?: string;
  isPopupOpen?: boolean;
}

const Tags = ({ tags, isOpen, tagsClassName = "", isPopupOpen }: TagsProps) => {
  return (
    <AnimationBlock
      visible={isOpen}
      mountClass={s.anim_opened}
      unmountClass={s.anim_closed}
      className={cn(s.anim, tagsClassName, { [s.popupOpen]: isPopupOpen })}
    >
      <ul className={s.root}>
        {tags.map(({ id, price, color }) => {
          return (
            <li className={s.tag} key={id}>
              <Dot color={color} />
              <p className={s.text}>{price} ₽</p>
            </li>
          );
        })}
      </ul>
    </AnimationBlock>
  );
};

export { Tags };
