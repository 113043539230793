import s from "./index.module.css";

import "leaflet/dist/leaflet.css";

// import map_plug from 'assets/images/map_plug.png';

import map_plug from "assets/images/map.png";

import L from "leaflet";
import { ImageOverlay, MapContainer, useMapEvents } from "react-leaflet";

import { MinimapControl } from "./components/MinimapControl";
import { useEffect, useRef, useState } from "react";

import cn from "classnames";
import { ChildZone } from "./components/Zones/ChildZone";
import { Zones } from "./types";
import { Footer } from "layouts/Footer";
import { Tags } from "./components/Tags";
import { Navigation } from "./components/Navigation";
import { MapController } from "./components/MapController";
import { INITIAL_MAP_WIDTH, MAP_BOUNDS, MAP_CENTER } from "./global";
import { WaterPoolZone } from "./components/Zones/WaterPoolZone";
import { MainZone } from "./components/Zones/MainZone";
import { VIPZone } from "./components/Zones/VIPZone";
import { OrderInfo } from "components/OrderInfo";
import { useWindowWidth } from "hooks/useWindowWidth";
import { PinModal } from "layouts/Modals/PinModal";
import { IPoolZone, IPrices, ITag, Steps } from "types";
import { useSelector } from "react-redux";
import {
  selectEventVenue,
  selectOrder,
  selectScheduleId,
} from "store/common/selectors";
import { getPricesMap } from "store/common/actions";
import { useDispatch } from "store/store";

const LocationFinderDummy = () => {
  const [state, setState] = useState<[number, number][]>([]);
  useMapEvents({
    click(e) {
      setState((prev) => [...prev, [e.latlng.lat, e.latlng.lng]]);
    },
  });
  return null;
};

interface MapProps {
  onFooterClick: () => void;
  onOrderInfoClick(): void;
  timeFromChosenTicket: string;
  chosenDate: Date;
  step: Steps | null;
}

const Map = ({
  onFooterClick,
  timeFromChosenTicket,
  chosenDate,
  onOrderInfoClick,
  step,
}: MapProps) => {
  const [currentZone, setCurrentZone] = useState<Zones | null>(null);
  const [isZooming, setIsZooming] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popupInfo, setPopupInfo] = useState<any>({});
  const [tags, setTags] = useState<ITag[]>([]);
  const windowWidth = useWindowWidth();
  const [orderedItems, setOrderedItems] = useState<IPoolZone[]>([]);
  const order = useSelector(selectOrder);
  const isOpen = !!orderedItems.length;
  const [uniquePinsId, setUniquePinsId] = useState<IPoolZone[]>([]);
  const scheduleId = useSelector(selectScheduleId);
  const venues = useSelector(selectEventVenue);
  const [minPrices, setMinPrices] = useState({
    vip: 0,
    big: 0,
    wave: 0,
    child: 0,
  });

  useEffect(() => {
    const mapOrder = uniquePinsId.filter((item) => {
      const inOrder = order?.find((orderItem) => orderItem.venueId === item.id);
      if (inOrder) {
        return item;
      }
    });
    setOrderedItems(mapOrder);
  }, [order, uniquePinsId]);

  const overlayRef = useRef<any>(null);

  const handleChangeZone = (zone: Zones) => {
    setCurrentZone(zone);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    let pins: IPrices[] = [];
    let formattedPins: IPoolZone[] = [];
    if (scheduleId) {
      Promise.allSettled(
        venues.map((venue) => {
          return dispatch(
            getPricesMap({
              serviceScheduleId: scheduleId,
              venueId: venue.id,
            })
          );
        })
      )
        .then((res) => {
          const newPrices: any = {
            vip: 0,
            big: 0,
            wave: 0,
            child: 0,
          };
          res.map((resItem) => {
            //@ts-ignore
            const price = resItem?.value?.payload
              ?.filter((item: any) => item.venue.y && item.venue.x)
              .sort(
                (a: any, b: any) =>
                  a.serviceSchedulePrice?.price - b.serviceSchedulePrice?.price
              )[0]?.serviceSchedulePrice.price;
            const zone = venues.find(
              //@ts-ignore
              (venue) => venue.id === resItem?.value?.payload[0]?.venue.parentId
            );
            if (zone) {
              newPrices[zone.fragmentCode] = price;
            }
          });
          setMinPrices(newPrices);

          res.map((r) => {
            //@ts-ignore
            pins = [...pins, ...r.value.payload];
          });
        })
        .then(() => {
          //@ts-ignore
          formattedPins = pins
            .map((item: IPrices) => {
              if (item.venue.y && item.venue.x) {
                return {
                  id: item.venue.id,
                  color: item.seatType.color ?? "#000000",
                  price: item?.serviceSchedulePrice?.price,
                  seatTypeId: item.seatType.id,
                  coords: [
                    INITIAL_MAP_WIDTH - item.venue.y * 2 - 150,
                    item.venue.x * 2 + 5,
                  ],
                  popupData: {
                    image: item.seatType.image ?? "/images/popup_icon.svg",
                    title: item.seatType.name,
                    zone: item.venue.parentVenueName,
                    description: item.seatType.description,
                  },
                };
              }
            })
            .filter((pin) => pin);
          setUniquePinsId(formattedPins);
        });
    }
  }, [venues, scheduleId, order]);

  useEffect(() => {
    if (overlayRef.current) {
      if (currentZone) {
        overlayRef.current._image.classList.add(`${s.image_zone}`);
      } else {
        overlayRef.current._image.classList.remove(`${s.image_zone}`);
      }
    }
  }, [currentZone]);

  const onMarkerClickHandler = (place: any) => {
    setIsModalOpen(true);
    setPopupInfo(place);
  };

  const onClosePinModalHandler = () => {
    setIsModalOpen(false);
    setPopupInfo({});
  };

  return (
    <>
      <div className={s.root}>
        <MapContainer
          maxBounds={MAP_BOUNDS}
          maxBoundsViscosity={1}
          zoom={-1}
          center={MAP_CENTER}
          maxZoom={1.3}
          minZoom={-5}
          crs={L.CRS.Simple}
          zoomControl={false}
          zoomSnap={0}
          zoomDelta={0.1}
          className={cn(s.map)}
        >
          <ImageOverlay
            url={map_plug}
            bounds={MAP_BOUNDS}
            className={s.image}
            ref={overlayRef}
          />
          {(!isPopupOpen || (windowWidth && windowWidth < 768)) && (
            <MinimapControl isOpen={currentZone && !isZooming} />
          )}

          {/* -----ZONES----- */}
          <ChildZone
            isZoneActive={currentZone === Zones.ChildZone}
            currentZone={currentZone}
            handleChangeZone={handleChangeZone}
            onMarkerClick={onMarkerClickHandler}
            setTags={setTags}
            minPrice={minPrices.child}
          />
          <WaterPoolZone
            isZoneActive={currentZone === Zones.WaterPoolZone}
            handleChangeZone={handleChangeZone}
            currentZone={currentZone}
            onMarkerClick={onMarkerClickHandler}
            setTags={setTags}
            minPrice={minPrices.wave}
          />
          <MainZone
            isZoneActive={currentZone === Zones.MainZone}
            handleChangeZone={handleChangeZone}
            currentZone={currentZone}
            onMarkerClick={onMarkerClickHandler}
            setTags={setTags}
            minPrice={minPrices.big}
          />
          <VIPZone
            isZoneActive={currentZone === Zones.VIPZone}
            handleChangeZone={handleChangeZone}
            currentZone={currentZone}
            onMarkerClick={onMarkerClickHandler}
            setTags={setTags}
            minPrice={minPrices.vip}
          />

          {/* others... (мб тут будет мап зон, пока хз)*/}
          {/* ---------- */}
          {/* Штука в дев режиме, чтобы знать куда кликнули в координатах */}
          {/* <LocationFinderDummy /> */}
          {/* Controller (нужен, чтобы иметь доступ к состояниями карты (но можно и реф)) */}
          <MapController
            setIsZooming={setIsZooming}
            currentZone={currentZone}
            setIsPopupOpen={setIsPopupOpen}
          />
        </MapContainer>
        {/* Map Interaction and Info */}
        <Tags
          tags={tags}
          isOpen={!!currentZone}
          tagsClassName={cn({ [s.tagVisible]: isOpen })}
          isPopupOpen={isPopupOpen}
        />
        <Navigation
          isOpen={currentZone! && !isZooming}
          zone={currentZone!}
          discardCurrentZone={() => setCurrentZone(null)}
          navClassName={cn({ [s.navVisible]: isOpen })}
          isPopupOpen={isPopupOpen}
        />
        <OrderInfo
          timeFromChosenTicket={timeFromChosenTicket}
          chosenDate={chosenDate}
          onClick={onOrderInfoClick}
          isPopupOpen={isPopupOpen}
        />
      </div>
      <Footer
        onClick={onFooterClick}
        isOpen={isOpen}
        orderedItems={orderedItems}
      />
      {isModalOpen &&
        step === Steps.Map &&
        popupInfo &&
        windowWidth &&
        windowWidth <= 768 && (
          <PinModal onClose={onClosePinModalHandler} popupInfo={popupInfo} />
        )}
    </>
  );
};

export { Map };
