import { FeatureGroup, SVGOverlay, useMap } from "react-leaflet";
import { useEffect, useRef, useState } from "react";

import { JSXMarker as Marker } from "layouts/Map/components/CustomMarker";

import cn from "classnames";

import s from "./index.module.css";

import { Zones } from "layouts/Map/types";

import useIsFirstRender from "hooks/useIsFirstRender";
import { useDispatch } from "store/store";
import { useSelector } from "react-redux";
import { selectEventVenue, selectScheduleId } from "store/common/selectors";
import { getPricesMap } from "store/common/actions";
import { IPoolZone, IPrices, ITag } from "types";
import { INITIAL_MAP_WIDTH } from "layouts/Map/global";

interface VIPZoneProps {
  isZoneActive: boolean;
  handleChangeZone: (zone: any) => void;
  currentZone: Zones | null;
  onMarkerClick: (place: any) => void;
  setTags: React.Dispatch<React.SetStateAction<ITag[]>>;
  minPrice: number;
}

const VIPZone = ({
  isZoneActive,
  handleChangeZone,
  currentZone,
  onMarkerClick,
  setTags,
  minPrice,
}: VIPZoneProps) => {
  const markersLayerRef = useRef<any>(null);
  const svgLayerRef = useRef<any>(null);
  const [pins, setPins] = useState<IPoolZone[]>([]);
  const dispatch = useDispatch();
  const scheduleId = useSelector(selectScheduleId);
  const venues = useSelector(selectEventVenue);

  const map = useMap();

  const firstRender = useIsFirstRender();

  useEffect(() => {
    if (svgLayerRef.current) {
      if (!currentZone && !firstRender) {
        svgLayerRef.current._image.classList.add(`${s.closed}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isZoneActive]);

  useEffect(() => {
    if (isZoneActive) {
      let venueIDMAP = null;
      if (venues.find((venue) => venue.fragmentCode === "vip")) {
        venueIDMAP = venues.find((venue) => venue.fragmentCode === "vip");
      }
      if (scheduleId && venueIDMAP) {
        dispatch(
          getPricesMap({
            serviceScheduleId: scheduleId,
            venueId: venueIDMAP.id,
          })
        ).then((res: any) => {
          const uniqueTags: any = {};
          const newPinsStructure: IPoolZone[] = res.payload.map(
            (item: IPrices) => {
              if (item.venue.y && item.venue.x) {
                if (item.seatType.color && item?.serviceSchedulePrice?.price) {
                  uniqueTags[item?.serviceSchedulePrice?.price] =
                    item.seatType.color;
                }
                return {
                  id: item.venue.id,
                  color: item.seatType.color ?? "#000000",
                  price: item?.serviceSchedulePrice?.price,
                  seatTypeId: item.seatType.id,
                  coords: [
                    INITIAL_MAP_WIDTH - item.venue.y * 2 - 150,
                    item.venue.x * 2 + 5,
                  ],
                  popupData: {
                    image: item.seatType.image ?? "/images/popup_icon.svg",
                    title: item.seatType.name,
                    zone: item.venue.parentVenueName,
                    description: item.seatType.description,
                  },
                };
              }
            }
          );

          const tags = Object.keys(uniqueTags)
            .map((item, index) => ({
              color: uniqueTags[item],
              price: +item,
              id: index,
            }))
            .sort((a, b) => a.price - b.price);
          setTags(tags);
          setPins(newPinsStructure.filter((pin) => pin));
        });
      }
    }
  }, [venues, scheduleId, isZoneActive]);

  const handleZoneClick = () => {
    handleChangeZone(Zones.VIPZone);
    if (map) {
      map.flyTo({ lng: 3914, lat: 854 }, -0.5);
    }
  };

  return (
    <>
      {isZoneActive && (
        <FeatureGroup ref={markersLayerRef}>
          {pins.map((place) => (
            //@ts-ignore
            <Marker
              position={place.coords}
              key={place.id}
              place={place}
              venueId={place.id}
              seatTypeId={place.seatTypeId}
              onMarkerClick={onMarkerClick}
            />
          ))}
        </FeatureGroup>
      )}

      {!currentZone && (
        <SVGOverlay
          bounds={[
            [149 * 2, 1490 * 2],
            [962 * 2, 2475 * 2],
          ]}
          attributes={{
            width: "956",
            height: "808",
            viewBox: "0 0 956 808",
            fill: "none",
          }}
          className={cn(s.overlay)}
          interactive={!isZoneActive}
          ref={svgLayerRef}
        >
          <circle cx="460" cy="350" r="150" className={s.text} />
          <text
            x="50%"
            y="50%"
            stroke="white"
            dx={10}
            height={50}
            style={{ fontWeight: 200 }}
            onClick={handleZoneClick}
          >
            <tspan
              text-anchor="middle"
              x="60%"
              y="40%"
              style={{ fontSize: "30px", fontWeight: "bold", fill: "#fff" }}
            >
              {venues.find((venue) => venue.fragmentCode === "vip")?.name}
            </tspan>
            <tspan
              text-anchor="middle"
              x="60%"
              y="47%"
              style={{ fontSize: "24px", fontWeight: "bold", fill: "#fff" }}
            >
              {minPrice ? ` от ${minPrice} ₽` : ""}
            </tspan>
          </text>

          <path
            className={s.zone3}
            d="M938.34 382.5C938.61 370.3 939.63 358.04 941.28 345.71C945.56 313.76 952.43 282.08 953.88 249.88C955.91 204.95 943.89 158.88 916.15 123.5C901.8 105.19 890.17 85.44 876.7 66.5C862.33 46.3 847.96 26.1 833.59 5.89003C824.97 -6.22997 814.65 9.29998 805.43 14.46C786.04 25.3 775.45 34.12 756.69 46.01C724.95 66.14 703.02 84.03 671.58 104.62C635.9 127.98 610.48 144.1 577.97 165.27C551.43 182.56 512.35 207.9 485.8 225.19C439.11 255.6 395.95 288.96 349.26 319.37C314.32 342.12 279.94 365.94 245.48 389.61C232.64 398.43 219.79 407.22 206.9 415.94C176.61 436.41 145.37 455.43 114.78 475.45C97.42 486.81 79.6999 497.62 62.6899 509.52C54.0199 515.58 46.0599 522.77 37.3599 528.72C30.2699 533.57 22.7499 536.4 15.8399 541.99C9.50991 547.1 0.589949 552.23 1.24995 561.65C2.75995 583.36 39.3699 594.71 54.9799 603.05C79.2999 616.04 103.56 629.12 127.83 642.2C175.88 668.09 223.94 693.99 272.34 719.23C304.85 736.18 337.51 752.83 370.4 769.03C387.34 777.37 404.33 785.6 421.39 793.7C436.98 801.1 450.26 813.2 466.39 802.37C505.56 775.97 543.56 747.96 582.5 721.25C671.82 659.99 763.52 602.16 853.46 541.79C873.18 528.55 892.91 515.32 912.81 502.35C924.47 494.75 946.06 487.31 948.31 471.57C949.22 465.18 947.61 458.74 946.01 452.48C940.1 429.26 937.82 405.99 938.34 382.5Z"
          />
        </SVGOverlay>
      )}
    </>
  );
};

export { VIPZone };
