import { useWindowWidth } from "hooks/useWindowWidth";
import { ArrowLeft, CrossIcon, LogoIcon } from "../../assets/icons";
import s from "./Head.module.css";
import cn from "classnames";

const Head = ({
  logoClassName = "",
  className = "",
  withLogo = false,
  textClassName = "",
  link,
  onClick,
  arrowType,
}: {
  className?: string;
  withLogo?: boolean;
  onClick?(): void;
  link?: string;
  textClassName?: string;
  arrowType?: string;
  logoClassName?: string;
}) => {
  const windowWidth = useWindowWidth();
  const CurrentTag = link ? "a" : "div";

  return (
    <div
      className={cn(s.root, className, {
        [s.root_logo]: withLogo || (windowWidth && windowWidth <= 768),
      })}
    >
      <CurrentTag
        href={link}
        target={link ? "_parent" : undefined}
        className={s.route}
        onClick={onClick}
      >
        {arrowType === "arrow" ? <ArrowLeft /> : <CrossIcon />}
        <span className={cn(s.text, textClassName)}>
          {" "}
          Билеты в Dream Beach Club
        </span>
      </CurrentTag>
      {(withLogo || (windowWidth && windowWidth <= 768)) && (
        <LogoIcon
          width={64}
          height={54}
          className={cn(s.logo, logoClassName)}
        />
      )}
    </div>
  );
};

export { Head };
