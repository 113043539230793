import { useCallback, useMemo, useState } from "react";
import {
  ImageOverlay,
  MapContainer,
  Rectangle,
  useMap,
  useMapEvent,
} from "react-leaflet";

import { useEventHandlers } from "@react-leaflet/core";

import map_plug from "assets/images/map.png";

import s from "./index.module.css";
import L from "leaflet";

import cn from "classnames";

const INITIAL_MAP_WIDTH = 5000;
const INITIAL_MAP_HEIGHT = 4846;

const imageBounds: [number, number][] = [
  [0, INITIAL_MAP_WIDTH],
  [INITIAL_MAP_HEIGHT, 0],
];

interface MinimapControlProps {}

const BOUNDS_STYLE = { weight: 1 };

const MinimapBounds = ({
  parentMap,
  zoom,
}: {
  parentMap: any;
  zoom: number;
}) => {
  const minimap = useMap();

  // Clicking a point on the minimap sets the parent's map center
  const onClick = useCallback(
    (e: any) => {
      parentMap.setView(e.latlng, parentMap.getZoom());
    },
    [parentMap]
  );
  useMapEvent("click", onClick);

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = useState(parentMap.getBounds());

  const onChange = useCallback(() => {
    // @ts-ignore
    const pos1 = Object.values(Object.values(parentMap.getBounds()).at(0));
    // @ts-ignore
    const pos2 = Object.values(Object.values(parentMap.getBounds()).at(1));

    setBounds(parentMap.getBounds());

    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(parentMap.getCenter(), zoom * 1.2);
  }, [minimap, parentMap, zoom]);

  // Listen to events on the parent map
  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), []);
  //@ts-ignore
  useEventHandlers({ instance: parentMap }, handlers);

  return (
    <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} className={s.rect} />
  );
};

const MinimapControl = ({ zoom, isOpen }: any) => {
  const parentMap = useMap();
  const mapZoom = zoom || -3.5;

  const minimap = useMemo(
    () => (
      <MapContainer
        center={parentMap.getCenter()}
        zoom={mapZoom}
        dragging={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomControl={false}
        crs={L.CRS.Simple}
        maxBounds={imageBounds}
        maxBoundsViscosity={1.0}
        minZoom={-3.5}
        className={s.minimap}
      >
        <ImageOverlay url={map_plug} bounds={imageBounds} className={s.image} />
        <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
      </MapContainer>
    ),
    []
  );

  return isOpen ? (
    <div className="leaflet-top leaflet-right">
      <div className={cn(s.control, "leaflet-control leaflet-bar")}>
        {minimap}
      </div>
    </div>
  ) : null;
};

export { MinimapControl };
