import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addToOrder,
  getEventSchedules,
  getEventVenueSeatTypeGroups,
  getShopEventPrices,
  getShopEventVenue,
  getShopEvents,
  getShopOrder,
  removeFromOrder,
  confirmOrder as confirmOrderService,
  processOrder as processOrderService,
} from "services";
import { ISchedules, IVenue } from "types";
import { toIsoString } from "utils/data";

import { actions as commonActions } from "store/common/reducer";
import { IAppState } from "store/reducers";
import { RETURN_URL } from "global/consts";

export const getServiceId = createAsyncThunk(
  "common/getServiceId",
  async (_, { rejectWithValue }) => {
    try {
      return await getShopEvents();
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const initializedOrder = createAsyncThunk(
  "common/initializedOrder",
  async (_, { rejectWithValue }) => {
    try {
      return await getShopOrder();
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getSheduleIdOneDate = createAsyncThunk(
  "common/getSheduleIdOneDate",
  async (
    params: { serviceId: number; date: Date },
    { rejectWithValue, dispatch }
  ) => {
    const { serviceId, date } = params;

    const dateStart = new Date(date.setHours(0, 0, 0, 0));
    const nextDateStart = new Date(
      new Date(date.setHours(0, 0, 0, 0)).setDate(date.getDate() + 1)
    );

    const options = {
      serviceId,
      from: toIsoString(dateStart),
      to: toIsoString(nextDateStart),
    };

    try {
      const schedulesResponce = await getEventSchedules(options);

      const serviceScheduleId = schedulesResponce[0].id;
      const venueId = schedulesResponce[0].venueId;

      return { serviceScheduleId, venueId };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getSheduleForMonth = createAsyncThunk(
  "common/getSheduleForMonth",
  async (params: { serviceId?: number; month: Date }, { rejectWithValue }) => {
    const { serviceId = null, month } = params;

    const firstMonthDay = new Date(month.getFullYear(), month.getMonth(), 1);
    const lastMonthDay = new Date(month.getFullYear(), month.getMonth() + 1, 1);

    const options = {
      serviceId,
      from: toIsoString(firstMonthDay),
      to: toIsoString(lastMonthDay),
    };

    try {
      const schedulesResponce = await getEventSchedules(options);
      const transformedResponse = schedulesResponce.map(
        (res: ISchedules) => res.from
      );
      return transformedResponse;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getGlobalEvents = createAsyncThunk(
  "common/getGlobalEvents",
  async (params: { serviceId: number; date: Date }, { rejectWithValue }) => {
    const { serviceId, date } = params;

    const dateStart = new Date(date.setHours(0, 0, 0, 0));
    const nextDateStart = new Date(
      new Date(date.setHours(0, 0, 0, 0)).setDate(date.getDate() + 1)
    );

    const options = {
      serviceId,
      from: toIsoString(dateStart),
      to: toIsoString(nextDateStart),
    };

    try {
      return await getEventVenueSeatTypeGroups(options);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getEventVenue = createAsyncThunk(
  "common/getEventVenue",
  async (
    params: { rootId: number; id: number },
    { rejectWithValue, dispatch }
  ) => {
    const { rootId, id } = params;
    try {
      const response: IVenue[] = await getShopEventVenue(rootId, id);

      const isMap = response.every((el) => el.kind !== 3);

      dispatch(commonActions.setIsMap(isMap));
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getPrices = createAsyncThunk(
  "common/getPrices",
  async (
    params: { serviceScheduleId: number; venueId: number },
    { rejectWithValue }
  ) => {
    const { serviceScheduleId, venueId } = params;

    try {
      return await getShopEventPrices(serviceScheduleId, venueId);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getPricesMap = createAsyncThunk(
  "common/getPricesMap",
  async (
    params: { serviceScheduleId: number; venueId: number },
    { rejectWithValue }
  ) => {
    const { serviceScheduleId, venueId } = params;
    try {
      return await getShopEventPrices(serviceScheduleId, venueId);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const addOrder = createAsyncThunk(
  "common/addOrder",
  async (
    params: {
      venueId: number;
      seatTypeId: number | null;
    },
    { rejectWithValue, getState }
  ) => {
    const state = getState() as IAppState;
    const {
      common: {
        scheduleOneDay: { scheduleId },
        publicId,
      },
    } = state;

    const { seatTypeId, venueId } = params;
    try {
      return await addToOrder({
        publicOrderId: publicId!,
        venueId: venueId,
        serviceScheduleId: scheduleId!,
        seatTypeId: seatTypeId,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const removeOrder = createAsyncThunk(
  "common/removeOrder",
  async (
    params: {
      orderDetailId: number;
    },
    { rejectWithValue, getState }
  ) => {
    const state = getState() as IAppState;
    const {
      common: { publicId },
    } = state;

    const { orderDetailId } = params;
    try {
      return await removeFromOrder({
        publicOrderId: publicId!,
        orderDetailId: orderDetailId,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const confirmOrder = createAsyncThunk(
  "common/confirmOrder",
  async (
    params: {
      orderId?: string;
      email: string;
      date: string;
    },
    { rejectWithValue, getState }
  ) => {
    const state = getState() as IAppState;
    const {
      common: { publicId },
    } = state;

    const { email, date, orderId } = params;
    try {
      return await confirmOrderService({
        publicOrderId: orderId ?? publicId!,
        returnUrl: `${RETURN_URL}?orderId=${publicId}${
          date ? `&date=${date}` : ""
        }`,
        email,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const processOrder = createAsyncThunk(
  "common/processOrder",
  async (
    params: {
      publicOrderId: string;
    },
    { rejectWithValue }
  ) => {
    const { publicOrderId } = params;

    try {
      return await processOrderService(publicOrderId);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
