import s from './index.module.css';

interface FallbackLoaderProps {}

const FallbackLoader = ({}: FallbackLoaderProps) => {
  return (
    <div className={s.root}>
      <span className={s.loader}></span>
    </div>
  );
};

export { FallbackLoader };
