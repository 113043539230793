import { CloseIcon } from "assets/icons";
import { Button } from "components/Button";
import { PortalModal } from "..";
import s from "./PinModal.module.css";
import { useSelector } from "react-redux";
import { selectOrder } from "store/common/selectors";
import { useEffect, useState } from "react";
import { IOrder } from "types";
import { addOrder, removeOrder } from "store/common/actions";
import { useDispatch } from "store/store";

interface PinModalProps {
  onClose(): void;
  popupInfo: any;
}

const PinModal = ({ onClose, popupInfo }: PinModalProps) => {
  const order = useSelector(selectOrder);
  const [isOrdered, setIsOrdered] = useState(false);
  const [orderId, setOrderId] = useState<IOrder | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const inOrder = order?.find((item) => item.venueId === popupInfo.id);
    if (inOrder) {
      setIsOrdered(true);
      setOrderId(inOrder);
    } else {
      setIsOrdered(false);
      setOrderId(null);
    }
  }, [order]);

  return (
    <PortalModal
      withHeader={false}
      onClickForClose={onClose}
      overlayType="blur"
    >
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.root}>
            <div className={s.main}>
              <div className={s.top}>
                <img
                  src={popupInfo.popupData.image}
                  alt="Лежак"
                  className={s.image}
                />
                <button className={s.close} onClick={onClose}>
                  <CloseIcon />
                </button>
              </div>
              <h3 className={s.title}>{popupInfo.popupData.title}</h3>
              <span className={s.zone}>{popupInfo.popupData.zone}</span>
              <div className={s.options}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: popupInfo.popupData.description,
                  }}
                />
              </div>
              <span className={s.price}>{popupInfo.price} ₽</span>
            </div>
            <Button
              className={s.button}
              onClick={() => {
                if (!isOrdered) {
                  dispatch(
                    addOrder({
                      venueId: popupInfo.id,
                      seatTypeId: popupInfo.seatTypeId,
                    })
                  );
                } else {
                  dispatch(removeOrder({ orderDetailId: orderId?.id ?? 1 }));
                }

                onClose();
              }}
            >
              {!isOrdered ? "Добавить в заказ" : "Убрать из заказа"}
            </Button>
          </div>
        </div>
      </div>
    </PortalModal>
  );
};

export { PinModal };
