import { Controller, UseControllerProps } from 'react-hook-form';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import { memo, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import s from './DatePicker.module.css';
import { useDispatch } from 'store/store';
import { getSheduleForMonth } from 'store/common/actions';
import { selectMountShedule } from 'store/common/selectors';

import { useSelector } from 'react-redux';

registerLocale('ru', ru);

interface DatePickerProps extends UseControllerProps {
  onChangeDate: (event: Date) => void;
  className?: string;
  chosenDate?: Date;
}

export const DatePicker = ({
  onChangeDate,
  className = '',
  chosenDate,
  control,
  name,
}: DatePickerProps) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const availableDates = useSelector(selectMountShedule);

  const transformedAvailableDates = availableDates.map((el: Date) => {
    return new Date(el).toLocaleDateString('ru-RU');
  });

  useEffect(() => {
    dispatch(getSheduleForMonth({ month: new Date() }));
  }, []);

  return (
    <div className={cn(s.root, className)}>
      <Controller
        name={name}
        control={control}
        defaultValue={new Date()}
        render={({ field: { onChange, value } }) => (
          <ReactDatePicker
            onChange={(event: Date) => {
              onChange(event);
              onChangeDate(event);
            }}
            minDate={new Date()}
            onChangeRaw={(e) => e.preventDefault()}
            open={true}
            locale='ru'
            dateFormat='dd MMMM yyyy'
            selected={chosenDate ? chosenDate : value}
            ref={ref}
            onMonthChange={(month) => {
              dispatch(getSheduleForMonth({ month }));
            }}
            filterDate={(d) =>
              transformedAvailableDates.includes(d.toLocaleDateString('ru-RU'))
            }
          />
        )}
      />
    </div>
  );
};
