import { EditIcon } from "assets/icons";
import { Button } from "components/Button";
import s from "./index.module.css";
import { useRef, useState } from "react";
import { IPoolZone, Steps } from "types";

import cn from "classnames";
import useDelayUnmount from "hooks/useDelayUnmount";

import { EditOrderModal } from "layouts/Modals/EditOrderModal";
import { useWindowWidth } from "hooks/useWindowWidth";

interface FooterProps {
  onClick: (step: Steps) => void;
  isOpen: boolean;
  orderedItems: IPoolZone[];
}

const Footer = ({ onClick, isOpen, orderedItems }: FooterProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const shouldRender = useDelayUnmount(isOpen, 300);
  const windowWidth = useWindowWidth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCloseModalClickHandler = () => setIsModalOpen(false);

  const totalSum = orderedItems
    .map((el) => el.price)
    .reduce((partialSum, a) => partialSum + a, 0);

  const lenght = orderedItems.length;
  return shouldRender ? (
    <div
      className={cn(s.footer, {
        [s.footer_opened]: isOpen,
        [s.footer_closed]: !isOpen,
      })}
      ref={ref}
    >
      <div className={s.left}>
        <span className={s.text}>Выбрано</span>
        <span className={s.result}>
          {`${lenght * 1} объекта на ${lenght * 2} человека`}
        </span>
      </div>
      <div className={s.right}>
        <span className={s.price}>{totalSum} ₽</span>
        <button className={s.icon} onClick={() => setIsModalOpen(true)}>
          <EditIcon />
        </button>
        {windowWidth && windowWidth > 600 && (
          <Button
            className={s.button}
            onClick={() => {
              onClick(Steps.PurchaseForm);
            }}
          >
            Продолжить
          </Button>
        )}
      </div>
      {windowWidth && windowWidth <= 600 && (
        <Button
          className={s.buttonBig}
          onClick={() => {
            onClick(Steps.PurchaseForm);
          }}
        >
          Продолжить
        </Button>
      )}
      <EditOrderModal
        isModalOpen={isModalOpen}
        onCloseModalClickHandler={onCloseModalClickHandler}
        orderedItems={orderedItems}
        totalSum={totalSum}
      />
    </div>
  ) : null;
};

export { Footer };
