import s from "./index.module.css";

import { DeleteIcon } from "assets/icons";

import cn from "classnames";
import { Dot } from "components/Dot";
import { useSelector } from "react-redux";
import { removeOrder } from "store/common/actions";
import { selectOrder } from "store/common/selectors";
import { useDispatch } from "store/store";
import { IPoolZone } from "types";

interface IOrderedItem {
  item: IPoolZone;
  icon: JSX.Element;
  lenght: number;
}

export const OrderedItem = ({ icon, lenght, item }: IOrderedItem) => {
  const dispatch = useDispatch();
  const order = useSelector(selectOrder);

  const onClickItem = () => {
    if (order?.length > 0) {
      const orderId = order.find((orderItem) => orderItem.venueId === item.id);
      if (orderId) dispatch(removeOrder({ orderDetailId: orderId.id }));
    }
  };
  return (
    <div className={cn(s.ticket, { [s.ticket_solo]: lenght === 1 })}>
      <div className={s.icon}>{icon}</div>
      <div className={s.block}>
        <h3 className={s.title}>{item.popupData.title}</h3>
        <span className={s.zone}>{item.popupData.zone}</span>
        <div className={s.bottom}>
          <p className={s.price}>{item.price} ₽</p>
          <Dot color={item.color} />
        </div>
      </div>

      <button className={s.button} onClick={onClickItem}>
        <DeleteIcon />
      </button>
    </div>
  );
};
