export const REGEXP_NAME = /^[?!,.а-яА-ЯёЁ0-9a-zA-Z\s]+$/;

export const REGEXP_SURMANE = REGEXP_NAME;

export const REGEXP_EMAIL =
  /^([a-zA-Z0-9-_\.]+|"[\w\s]+")@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;

export const REGEXP_PHONE =
  /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

// useEffect(() => {
//     const mindboxInit = `
//       mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
//       mindbox.queue = mindbox.queue || [];
//       mindbox('create');
//       `;
//     // eslint-disable-next-line no-new-func
//     const mindboxInitFunction = new Function(mindboxInit);
//     mindboxInitFunction();
//   }, []);

//   useEffect(() => {
//     const mindboxPageViewInit = `
//         mindbox('async', {
//           operation: 'Website.PageView',
//           data: {
//             customerAction: {
//               customFields: {
//                 websitePageView: 'test',
//               },
//             },
//           },
//         });`;
//     // eslint-disable-next-line no-new-func
//     const minboxPageView = new Function(mindboxPageViewInit);
//     minboxPageView();
//   }, []);
