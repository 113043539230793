import { IAppState } from 'store/reducers';
import { RequestStatus } from 'types';

export const isGlobalLoading = (state: IAppState) => {
  return state.common.requestStatus === RequestStatus.Pending;
};

export const isOrderLoading = (state: IAppState) => {
  return state.common.requestOrderStatus === RequestStatus.Pending;
};

export const selectServiceId = (state: IAppState) => {
  return state.common.serviceId;
};

export const selectMountShedule = (state: IAppState) => {
  return state.common.scheduleForMonth;
};

export const selectScheduleId = (state: IAppState) => {
  return state.common.scheduleOneDay.scheduleId;
};

export const selectGlovbalEvents = (state: IAppState) => {
  return state.common.globalEvents;
};

export const selectCurrentGlobalEvent = (state: IAppState) => {
  return state.common.currentGlobalEvent;
};

export const selectIsMap = (state: IAppState) => {
  return state.common.isMap;
};

export const selectEventVenue = (state: IAppState) => {
  return state.common.eventVenue;
};

export const selectCurrentPricesFree = (state: IAppState) => {
  return state.common.currentPricesFree;
};

export const selectOrder = (state: IAppState) => {
  return state.common.order;
};

export const selectTotalOrderSum = (state: IAppState) => {
  return state.common.order.reduce((acc, el) => el.basePrice + acc, 0);
};
