import { useEffect, useState } from "react";
import { DatePickerModal } from "./layouts/Modals/DatePickerModal";
import { TicketModal } from "./layouts/Modals/TicketModal";
import s from "./App.module.css";

import { PurchaseFormModal } from "layouts/Modals/PurchaseFormModal";
import { PaymentStateModal } from "layouts/Modals/PaymentStateModal";

import { Steps } from "types";
import { Aside } from "layouts/Aside";

import { AdditionalTicketsBlock } from "layouts/Modals/AdditionalTicketsBlock";
import { Map } from "layouts/Map";

import { useWindowWidth } from "hooks/useWindowWidth";

import { GlobalPreloader } from "guards/GlobalPreloader";
import { useDispatch } from "store/store";
import {
  getGlobalEvents,
  getServiceId,
  getSheduleIdOneDate,
  initializedOrder,
} from "store/common/actions";

import { selectIsMap, selectServiceId } from "store/common/selectors";
import { useSelector } from "react-redux";
import { IAppState } from "store/reducers";
import { Head } from "components/Head";
import { PaymentReturnGuard } from "guards/PaymentReturnGuard";

import { actions as commonActions } from "store/common/reducer";

const App = () => {
  const [step, setStep] = useState<Steps>(Steps.DateChoice);
  const [chosenDate, setChosenDate] = useState(new Date());
  const [timeFromChosenTicket, setTimeFromChosenTicket] = useState<string>("");

  const dispatch = useDispatch();

  const serviceId = useSelector(selectServiceId);
  const isMap = useSelector(selectIsMap);

  const state = useSelector((state: IAppState) => state.common);

  const windowWidth = useWindowWidth();

  useEffect(() => {
    dispatch(getServiceId()).then(() => {
      dispatch(commonActions.clearOrder());
      dispatch(initializedOrder());
    });
  }, [dispatch, chosenDate]);

  const onClickDate = async (date: Date) => {
    setChosenDate(date);
    dispatch(getSheduleIdOneDate({ serviceId: serviceId!, date })).then(() => {
      dispatch(getGlobalEvents({ serviceId: serviceId!, date }));
    });
  };

  const onTicketClickHandler = (time: string) => {
    setTimeFromChosenTicket(time);
  };

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 300);
  }, []);

  const onStepChangeHandler = (step: Steps) => {
    setStep(step);
  };

  return (
    <PaymentReturnGuard setStep={setStep}>
      <GlobalPreloader
        showLoading={step !== Steps.Map && step !== Steps.PaymentState}
      >
        {windowWidth && windowWidth <= 768 && (
          <Head
            withLogo
            onClick={() => setStep(Steps.TicketType)}
            arrowType="arrow"
          />
        )}
        <div className={s.root}>
          {windowWidth && windowWidth > 900 && (
            <Aside
              onClickDate={onClickDate}
              chosenDate={chosenDate}
              onTicketClick={onTicketClickHandler}
            />
          )}
          {isMap ? (
            <Map
              onFooterClick={() => {
                setStep(Steps.PurchaseForm);
              }}
              step={step}
              timeFromChosenTicket={timeFromChosenTicket}
              chosenDate={chosenDate}
              onOrderInfoClick={() => setStep(Steps.DateChoice)}
            />
          ) : (
            <div className={s.modalWrapper}>
              {windowWidth && windowWidth > 768 && windowWidth <= 900 && (
                <Head
                  withLogo
                  onClick={() => setStep(Steps.TicketType)}
                  arrowType="arrow"
                  className={s.modalHeader}
                  logoClassName={s.modalHeaderLogo}
                />
              )}
              <div className={s.modalContent}>
                <AdditionalTicketsBlock
                  onClick={() => {
                    setStep(Steps.PurchaseForm);
                  }}
                  chosenDate={chosenDate}
                  className={s.modalBlock}
                />
              </div>
            </div>
          )}
        </div>

        {step === Steps.DateChoice && (
          <DatePickerModal
            onClickDate={(date) => {
              onClickDate(date).then(() => setStep(Steps.TicketType));
            }}
          />
        )}
        {step === Steps.TicketType && (
          <TicketModal
            onCloseClick={() => setStep(Steps.DateChoice)}
            onClick={() => setStep(Steps.Map)}
            onTicketClick={onTicketClickHandler}
          />
        )}
        {step === Steps.PurchaseForm && (
          <PurchaseFormModal
            onClickForClose={() => {
              setStep(Steps.Map);
            }}
            chosenDate={chosenDate}
          />
        )}
        {step === Steps.PaymentState && (
          <PaymentStateModal setStep={onStepChangeHandler} />
        )}
      </GlobalPreloader>
    </PaymentReturnGuard>
  );
};

export default App;
