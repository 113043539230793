import { useMap, useMapEvents } from "react-leaflet";
import { Zones } from "../types";
import { useEffect } from "react";
import { MAP_CENTER } from "../global";
import useIsFirstRender from "hooks/useIsFirstRender";
import { useWindowWidth } from "hooks/useWindowWidth";

interface MapControllerProps {
  setIsZooming: React.Dispatch<React.SetStateAction<boolean>>;
  currentZone: Zones | null;
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const MapController = ({
  setIsZooming,
  currentZone,
  setIsPopupOpen,
}: MapControllerProps) => {
  const map = useMap();
  const isFirstRender = useIsFirstRender();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    //отключаем нахуй зум, чтобы потом не плакать (как девочки)
    map.doubleClickZoom.disable();
    map.touchZoom.disable();
    map.scrollWheelZoom.disable();
  }, []);

  useMapEvents({
    popupopen: () => setIsPopupOpen(true),
    popupclose: () => setIsPopupOpen(false),
  });

  useEffect(() => {
    if (!currentZone && !isFirstRender) {
      let zoom = -1;
      if (windowWidth) {
        if (windowWidth <= 2650) {
          zoom = -1.1;
        }
        if (windowWidth <= 2000) {
          zoom = -1.6;
        }
        if (windowWidth <= 1500) {
        }
      }
      map.setZoom(zoom);
    }
  }, [map, windowWidth, currentZone]);

  useMapEvents({
    zoomstart: () => setIsZooming(true),
    zoomend: () => setIsZooming(false),
  });

  return <></>;
};
