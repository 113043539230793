import { FieldValues, useForm } from "react-hook-form";
import { PortalModal } from "../index";
import { DatePicker } from "components/DatePicker";

import s from "./index.module.css";

import "./DatePickerModal.css";

import { useRef } from "react";

const DatePickerModal = ({
  onClickDate,
}: {
  onClickDate: (date: Date) => void;
}) => {
  const ref = useRef<HTMLFormElement>(null);
  const { control, handleSubmit } = useForm<FieldValues>({
    mode: "onChange",
  });

  const onSubmit = (data: FieldValues) => {
    onClickDate(data.date);
  };

  return (
    <>
      <PortalModal
        onClickForClose={() => {}}
        headerLink="https://dreamisland.ru/dream-beach/"
        headerClassName={s.head}
        headerTextClassName={s.headerText}
        overlayType="blur"
      >
        <div className={s.wrapper}>
          <form className={s.root} ref={ref} onSubmit={handleSubmit(onSubmit)}>
            <span className={s.title}>Выберите дату</span>
            <DatePicker
              control={control}
              onChangeDate={() => {
                if (ref.current) {
                  ref.current.requestSubmit();
                }
              }}
              className={s.datepicker}
              name="date"
            />
          </form>
        </div>
      </PortalModal>
    </>
  );
};

export { DatePickerModal };
